export const LANG_KEY = 'lang';
export const AUTH_KEY = 'auth';
export const USER_KEY = 'user';
export const JAPANESE_KEY = 'ja';
export const ENGLISH_KEY = 'en';
export const LANGUAGES = [ENGLISH_KEY, JAPANESE_KEY];
export const DEFAULT_LANG = JAPANESE_KEY;
export const BRIDGE = 'BRIDGE';
export const BOOK = 'BOOK';
export const BRIDGE_BOOK = 'BRIDGE and BOOK';
export const POST_CODE = 'Post Code';
export const PREFECTURE = 'Prefectures';
export const ADDRESS = 'Street Address';
export const ADDRESS_2 = 'Address 2';
export const PLAN = '予約可';
export const FEATURES = 'LGBTフレンドリー';
export const FREE_WIFI = '無料Wi-fi';
export const PAID_WIFI = '有料Wi-fi';
export const BRIDGE_ID = 1;
export const BOOK_ID = 2;
export const BRIDGE_BOOK_ID = 3;
export const CMS_PLAN_ID = 4;
export const IG_CONSUL = 5;
export const IM = 6;
export const GROUP_KEY = 'groups';
export const LOCATION_KEY = 'locations';
export const YEN = '¥ ';
export const MENU_MODE_KEY = 'menuMode';
export const DEFAULT_MENU_MODE = 'sidebar';
export const SELECTED_LOCATION_ID_KEY = 'selectedLocationID';
export const HAS_SERVICE_ITEMS_KEY = 'hasServiceItems';
export const HAS_FOOD_MENUS_KEY = 'hasFoodMenus';
export const SERVICE_GMB = 1;
export const SERVICE_CMS = 2;
export const SERVICE_TIKTOK = 3;
export const SERVICE_HPB = 4;
export const SERVICE_REVIEW = 5;
export const SERVICE_LINE_OFFICIAL = 6;
export const SERVICE_AUTO_REPLY = 7;
export const SERVICE_HITOSARA = 8;
export const IS_IG_CONSULT_KEY = 'isIgConsult';
export const TOAST_SKIPPING_KEYS = [
  'NO_LOCATIONS_CONNECTED_TO_YPLACE',
  'YPLACE_DATA_NOT_SYNCED_YET',
];
